import type { Epic } from 'behavior/types';
import type { DocumentsResponse } from '../types';
import { DOCUMENTS_REQUESTED, documentsReceived, DocumentsAction } from '../actions';
import { DocumentType } from 'behavior/documents';
import {
    ordersQuery,
    quotesQuery,
    invoicesQuery,
    returnOrdersQuery,
    creditNotesQuery,
    returnReceiptsQuery,
    shipmentsQuery,
    myMillTestReportsQuery, //Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
} from '../queries';
import { switchMap, pluck, takeUntil, mergeMap, startWith } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

const epic: Epic<DocumentsAction> = (action$, _, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
    const setLoading = setLoadingIndicator();
    const unsetLoading = unsetLoadingIndicator();

    return action$.pipe(
        ofType(DOCUMENTS_REQUESTED),
        switchMap(({ payload: { options, documentType } }) => {
            const query = getDocumentQuery(documentType);
            if (!query) {
                logger.error(`Query for document of '${documentType}' type is not registered.`);
                return EMPTY;
            }

            return api.graphApi<DocumentsResponse>(query, { options }).pipe(
                pluck('documents', 'docs'),
                mergeMap(documents => of(
                    documentsReceived(documents && documents.list, options.page.index),
                    unsetLoading,
                )),
                retryWithToast(action$, logger, _ => of(unsetLoading)),
                takeUntil(locationChanged$),
                startWith(setLoading),
            );
        }),
    );
};

export default epic;

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
function getDocumentQuery(documentType: DocumentType) {
    switch (documentType) {
        case DocumentType.Order:
            return ordersQuery;
        case DocumentType.Quote:
            return quotesQuery;
        case DocumentType.Invoice:
            return invoicesQuery;
        case DocumentType.ReturnOrder:
            return returnOrdersQuery;
        case DocumentType.CreditNote:
            return creditNotesQuery;
        case DocumentType.ReturnReceipt:
            return returnReceiptsQuery;
        case DocumentType.Shipment:
            return shipmentsQuery;
        case DocumentType.MyMillTestReports:
            return myMillTestReportsQuery;
        default:
            return;
    }
}
