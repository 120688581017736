import { RouteName } from './RouteName';

const nonIndexed = new Set([
    RouteName.BasketPage,
    RouteName.NotFound,
    RouteName.WishList,
    RouteName.Represent,
    RouteName.PagePreview,
    RouteName.LastViewedProducts,
    RouteName.OrderTemplates,
    RouteName.NewsletterSubscribe,
    RouteName.NewsletterUnSubscribe,
    RouteName.Search,
    RouteName.Orders,
    RouteName.DocumentDetails,
    RouteName.ReturnOrders,
    RouteName.MyAccount,
    RouteName.EditProfile,
    RouteName.Quotes,
    RouteName.Invoices,
    RouteName.ReturnReceipts,
    RouteName.CreditNotes,
    RouteName.Shipments,
    RouteName.InvoicePayment,
    RouteName.SalesAgreements,
    RouteName.CreateDocFreeReturnOrder,
    RouteName.CreateDocBasedReturnOrder,
    RouteName.SubAccounts,
    RouteName.EditSubAccount,
    RouteName.NewSubAccount,
    RouteName.OrderAuthorizations,
    RouteName.CreateProspect,
    RouteName.ChangePassword,
    RouteName.PagePreview,
    RouteName.Checkout,
    RouteName.OrderSubmit,
    RouteName.PaymentError,
    RouteName.OrderFailed,
    RouteName.OrderCancelled,
    RouteName.ProductComparison,
    RouteName.CreateProspect,
    RouteName.MyMillTestReports, //Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
]);

/**
 * Checks whether route belongs to indexed pages.
 * @param {RouteName} routeName - route name.
 * @returns {boolean} value indicating whether route should be indexed.
 */
export const isIndexed = (routeName: RouteName): boolean => !nonIndexed.has(routeName);
