import type { DocumentsCollection } from './types';
import {
    DOCUMENTS_RECEIVED,
    UPDATE_SORT_FIELD,
    UPDATE_SEARCH_CRITERIA,
    DocumentsAction,
    DocumentsReceivedAction,
    UpdateSortFieldAction,
    UpdateSearchCriteriaAction
} from './actions'; //Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
import { createReducer } from 'utils/redux';

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
type State = {
    docs: DocumentsCollection | null;
    selectedSortingOption: string;
    sortAsc: false;
    documentId: '';
    heatId: '';
    customerPo: '',
    itemId: '';
    from: '';
    to: '';

};

type Action =
    | UpdateSortFieldAction
    | DocumentsReceivedAction
    | DocumentsAction
    | UpdateSearchCriteriaAction

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
export default createReducer<State, Action>(null as any, {
    [DOCUMENTS_RECEIVED]: onDocumentsReceived,
    [UPDATE_SORT_FIELD]: onSortFieldUpdated,
    [UPDATE_SEARCH_CRITERIA]: onSearchCriteriaChanged,
});

function onDocumentsReceived(state: State, action: DocumentsReceivedAction) {
    const {
        documents,
        page,
    } = action.payload;

    if (!state.docs || page === 0 || !documents)
        return {
            ...state,
            docs: documents,
        };

    const { items, totalCount } = documents;
    return {
        ...state,
        docs: {
            items: state.docs.items.concat(items),
            totalCount,
        },
    };
}

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
function onSortFieldUpdated(state: State, action: any): State {
    const { sortField, sortAsc } = action.payload;
    return {
        ...state,
        selectedSortingOption: sortField,
        sortAsc: sortAsc
    };
}

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
function onSearchCriteriaChanged(state: State, action: any): State {
    const { options } = action.payload;
    return {
        ...state,
        from: options.orderDate.from,
        to: options.orderDate.to,
    };
}