import type { Handler } from 'behavior/pages/types';
import type { PromotionResult, Document } from './types';
import type { AppState } from 'behavior';
import type { Api } from 'utils/api';
import type { LoadedSettings } from 'behavior/settings';
import { PageComponentNames } from '../componentNames';
import { of } from 'rxjs';
import { map, mergeMap, first } from 'rxjs/operators';
import { DocumentAbilitiesMap, DocumentType, getDocumentDetailsField } from 'behavior/documents';
import { createAbilityMiddleware } from '../middleware';
import { CustomerType } from 'behavior/user/constants';
import { getDocumentPageQuery } from './queries';
import { printModeEnabled } from 'behavior/printMode';
import { isQuoteInStock } from './helpers';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { RouteName } from 'routes';

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
const pageComponentNamesPerDocumentType = {
    [DocumentType.Order]: PageComponentNames.Order as const,
    [DocumentType.Quote]: PageComponentNames.Quote as const,
    [DocumentType.Invoice]: PageComponentNames.Invoice as const,
    [DocumentType.ReturnOrder]: PageComponentNames.ReturnOrder as const,
    [DocumentType.CreditNote]: PageComponentNames.CreditNote as const,
    [DocumentType.ReturnReceipt]: PageComponentNames.ReturnReceipt as const,
    [DocumentType.Shipment]: PageComponentNames.Shipment as const,
    [DocumentType.MyMillTestReports]: PageComponentNames.MyMillTestReports as const,
};

const handler: Handler<DocumentRouteData, DocumentPage> = (routeData, state$, dependencies) => {
    const { params: { id, documentType, originalOrderId: orderId, previewToken } } = routeData;
    const { api } = dependencies;

    if (!documentType)
        return of(null);

    const isPrintMode = printModeEnabled(state$.value.routing);

    const createPageResult: PageResultCreator = (page, document) => {

        return {
            page: {
                ...page,
                component: pageComponentNamesPerDocumentType[documentType],
                id,
                isPrintMode,
                promotionResult: (state$.value.page as DocumentPage).promotionResult,
                document,
            },
        };
    };

    if (previewToken)
        return handlePreview(api, documentType, createPageResult);

    const ability = DocumentAbilitiesMap[documentType];
    const abilityMiddleware = createAbilityMiddleware<DocumentRouteData, DocumentPage>(ability);

    const loadDocumentPage = (state: AppState) => {
        const {
            tax: { mode: taxMode },
            checkout: { maxOverviewLines },
        } = state.settings as LoadedSettings;

        const query = getDocumentPageQuery(documentType, state.user.customerType !== CustomerType.B2C, taxMode);

        return api.graphApi<DocumentPageResponse>(query, {
            id,
            linesLimit: isPrintMode ? null : maxOverviewLines,
            orderId,
        }).pipe(
            mergeMap(({ pages, documents: { doc } }) => {
                const page = pages.doc;
                if (!page)
                    return of(null);

                const initializedPage = initPageContent(page);

                if (!doc || !doc.byId)
                    return of(null);

                const document = {
                    ...doc.byId,
                    documentType,
                } as Document;

                if (document.documentType === DocumentType.Quote)
                    return of(createPageResult(initializedPage, { ...document, hasStock: isQuoteInStock(document) }));

                return of(createPageResult(initializedPage, document));
            }),
        );
    };

    const next = () => {
        if (state$.value.settings.loaded)
            return loadDocumentPage(state$.value);

        return state$.pipe(
            first(s => s.settings.loaded),
            mergeMap(loadDocumentPage),
        );
    };

    return abilityMiddleware(next, routeData, state$, dependencies);
};

export default handler;

type DocumentSystemPageResponse = {
    pages: {
        [documents in ReturnType<typeof getDocumentDetailsField>]: SystemPageData;
    };
};

function handlePreview(api: Api, documentType: DocumentType, createPageResult: PageResultCreator) {
    const pageField = getDocumentDetailsField(documentType);

    return api.graphApi<DocumentSystemPageResponse>(loadSystemPageQuery(pageField)).pipe(
        map(({ pages: { [pageField]: page } }) => {
            if (!page)
                return null;

            const previewDocument: Document = {
                documentType: DocumentType.Order,
                id: '',
                status: 'OPEN',
                documentId: '',
                currency: {
                    id: '',
                    cultureName: '',
                    decimalDigits: 0,
                    symbol: '',
                },
                shipping: {
                    methodName: null,
                    status: null,
                    tracking: {
                        number: null,
                        link: null,
                    },
                },
                payment: {
                    status: null,
                    isPaidOnAccount: false,
                    transaction: null,
                    discountDate: null,
                    methodName: null,
                },
                addresses: {
                    billing: null,
                    payer: null,
                    shipping: null,
                },
                lines: { totalCount: 0, itemLines: [] },
                totals: null,
                canReorder: null,
                orderDate: '',
                requestedDeliveryDate: null,
                referenceNo: null,
                comments: null,
                pickupLocation: null,
                attachments: [],
                reportUrl: null,
                editable: false,
                authorizationInfo: null,
            };

            const initializedPage = initPageContent(page);

            return createPageResult(initializedPage, previewDocument);
        }));
}

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
type DocumentPageComponentName =
    | PageComponentNames.Order
    | PageComponentNames.Quote
    | PageComponentNames.Invoice
    | PageComponentNames.ReturnOrder
    | PageComponentNames.CreditNote
    | PageComponentNames.ReturnReceipt
    | PageComponentNames.Shipment
    | PageComponentNames.MyMillTestReports

type DocumentRouteData = {
    routeName: RouteName.DocumentDetails;
    params: {
        id: string;
        documentType?: DocumentType;
        originalOrderId: string;
        orderId: string;
        previewToken?: string;
    };
};

type DocumentPage = SystemPage & {
    component: DocumentPageComponentName;
    document: Document;
    id: string;
    isPrintMode: boolean;
    promotionResult?: PromotionResult;
};

type PageResultCreator = (page: SystemPage, document: Document) => { page: DocumentPage };

type DocumentPageResponse = {
    pages: {
        doc: SystemPageData;
    };
    documents: {
        doc: { byId: Omit<Document, 'documentType'> };
    };
};
