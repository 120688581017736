import type { DocumentType } from 'behavior/documents';
import { DocumentsCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
};

type SortField = {
    sortOption: '';
};

type SortAsc = {
    sortAsc: false;
};

export const DOCUMENTS_REQUESTED = 'DOCUMENTS_REQUESTED' as const;
export const requestDocuments = (options: Options, documentType: DocumentType) => ({
  type: DOCUMENTS_REQUESTED,
  payload: { options, documentType },
});

export const DOCUMENTS_RECEIVED = 'DOCUMENTS_RECEIVED' as const;
export const documentsReceived = (documents: DocumentsCollection | null, page: number) => ({
  type: DOCUMENTS_RECEIVED,
  payload: { documents, page },
});

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
export const UPDATE_SORT_FIELD = 'UPDATE_SORT_FIELD' as const;
export const updateSortField = (sortField: SortField, sortAsc: SortAsc) => ({
    type: UPDATE_SORT_FIELD,
    payload: { sortField, sortAsc },
});

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA' as const;
export const updateSearchCriteria = (options: any) => ({
    type: UPDATE_SEARCH_CRITERIA,
    payload: { options },
});


export type DocumentsReceivedAction = ReturnType<typeof documentsReceived>;
export type DocumentsAction = ReturnType<typeof requestDocuments> | DocumentsReceivedAction;

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
export type UpdateSortFieldAction = ReturnType<typeof updateSortField>;
export type UpdateSearchCriteriaAction = ReturnType<typeof updateSearchCriteria>;
