import { AbilityTo } from 'behavior/user/constants';

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
export const enum DocumentType {
    Order = 'Order',
    Quote = 'Quote',
    Invoice = 'Invoice',
    ReturnOrder = 'ReturnOrder',
    ReturnReceipt = 'ReturnReceipt',
    Shipment = 'Shipment',
    CreditNote = 'CreditNote',
    MyMillTestReports = 'MyMillTestReport',
}

//Ticket 220268: [UA] P3 - 3.1.Generic MTR Search
export const DocumentAbilitiesMap = {
    [DocumentType.Order]: AbilityTo.ViewOrders,
    [DocumentType.Quote]: AbilityTo.ViewQuotes,
    [DocumentType.Invoice]: AbilityTo.ViewInvoices,
    [DocumentType.ReturnOrder]: AbilityTo.ViewReturnOrders,
    [DocumentType.ReturnReceipt]: AbilityTo.ViewReturnReceipts,
    [DocumentType.Shipment]: AbilityTo.ViewShipments,
    [DocumentType.CreditNote]: AbilityTo.ViewCreditNotes,
    [DocumentType.MyMillTestReports]: AbilityTo.ViewMyMillTestReports,
} as const;

export const enum ReorderResult {
    Success = 'SUCCESS',
    BasketNotEmpty = 'BASKET_NOT_EMPTY',
    NoProducts = 'NO_PRODUCTS',
    OrderNotFound = 'ORDER_NOT_FOUND',
}

export const enum EditResult {
    Success = 'SUCCESS',
    BasketNotEmpty = 'BASKET_NOT_EMPTY',
    NoDocument = 'NO_DOCUMENT',
}

export const enum QuoteStatus {
    Expired = 'Expired',
    InReview = 'InReview',
    Orderable = 'Orderable',
    Cancelled = 'Cancelled',
}

export const enum PromoteQuoteStatus {
    Succeed = 'SUCCEED',
    CreditLimitForceOnlinePayment = 'CREDIT_LIMIT_FORCE_ONLINE_PAYMENT',
    CreditLimitWarning = 'CREDIT_LIMIT_WARNING',
    CreditLimitError = 'CREDIT_LIMIT_ERROR',
    OutOfStock = 'OUT_OF_STOCK',
    ContainsNonOrderableProducts = 'CONTAINS_NON_ORDERABLE_PRODUCTS',
    QuoteNotFound = 'QUOTE_NOT_FOUND',
}

export const ChildDocumentTypes = [
    DocumentType.Invoice,
    DocumentType.ReturnReceipt,
    DocumentType.CreditNote,
    DocumentType.Shipment,
];
