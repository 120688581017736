import Column from './ContentBlockColumn';
import { VdColumnContainer } from 'components/visualDesigner';
import { useSelector } from 'react-redux';
import { CustomContentBlockIds } from './constants';

export default function createColumn(column, index, fullWidthRow, rowVerticalAlignment) {

  const verticalAlignment = column.verticalAlignment === 'INHERITED' ? rowVerticalAlignment : column.verticalAlignment;

  //Ticket 151526 3.5. Control Content Visibility for Maintenance Mode
  var isMaintananceModeActivated = useSelector(state => state.app.offlineMode);
  var isVisualDesigner = useSelector(state => state.visualDesigner.initialized);
  var visibilityOnlyForMaintananceMode = false;
  var componentName = "";
  var columnCount = Object.keys(column.contentBlocks).length;

  for (let i = 0; i < columnCount; i++) {
    componentName = column.contentBlocks[i].name;
    if ((componentName === CustomContentBlockIds.CustomParagraph) || (CustomContentBlockIds.CustomCode) || (CustomContentBlockIds.CustomFlexiTile)) {
      if (componentName === CustomContentBlockIds.CustomFlexiTile) {
        visibilityOnlyForMaintananceMode = column.contentBlocks[i].model.background.visibleOnlyForMaintananceMode;
      } else {
        visibilityOnlyForMaintananceMode = column.contentBlocks[i].model.visibleOnlyForMaintananceMode;
      }
    }
  }
  //Ticket 151526 3.5. Control Content Visibility for Maintenance Mode
  if (visibilityOnlyForMaintananceMode) {
    if (!isMaintananceModeActivated && (isVisualDesigner === false)) {
      return (null);
    }
  }

  return (
    <VdColumnContainer key={column.id} index={index} column={column}>
      <Column {...column} fullWidthRow={fullWidthRow} verticalAlignment={verticalAlignment} />
    </VdColumnContainer>
  );
}