import type { Filter, DocumentsCollection, DocumentsResponse } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestDocuments } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';
import { loadSystemPageQuery } from '../system/queries';
import { getDocumentField, DocumentType } from 'behavior/documents';

export const size = 10;

export default function (documentType: DocumentType, componentName: PageComponentNames, query: string) {
    const handler: Handler<DocumentsRouteData, DocumentsPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
        const filter = normalizeFilter(params && params.filter);
        if (params?.previewToken) {
            const documentsFieldName = getDocumentField(documentType);

            return api.graphApi<DocumentsPreviewPageResponse>(loadSystemPageQuery(documentsFieldName)).pipe(
                map(({ pages }) => {
                    const page = pages[documentsFieldName];
                    if (!page)
                        return null;

                    return {
                        page: {
                            component: componentName,
                            docs: { totalCount: 0, items: [] },
                            size,
                            filter,
                            ...page,
                        },
                    };
                }),
                initSystemPageContent(),
            );
        }

        const onlyItems = pageOptions && pageOptions.onlyItems;
        const backTo = pageOptions && pageOptions.backTo;
        const options = createOptions(params, filter, onlyItems);

        //Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
        const pageState = state$.value.page as FilterDoc;
        const start = pageState?.from;
        const end = pageState?.to;
        const isDateSelected = start !== undefined || end !== undefined;
        if (componentName === PageComponentNames.MyMillTestReports) {

            options.sortAscending = params !== undefined ? params.sortAscending : false;
            options.sortField = params !== undefined ? params.sortField : "";

            if (!isDateSelected) {
                filter.orderDate.from = null;
            }
        }


        if (onlyItems) {
            return of({
                action$: of(requestDocuments(options, documentType)),
                page: {
                    ...state$.value.page as DocumentsPage,
                    filter,
                },
            });
        }
          

        return api.graphApi<DocumentsPageResponse>(query, { options }).pipe(
            map(({ pages: { page }, documents: pageDocuments }) => {
                if (!page)
                    return null;

                const initializedPage = initPageContent(page);
                const documents = pageDocuments.docs && pageDocuments.docs.list;

                if (!documents) {
                    return {
                        page: {
                            component: PageComponentNames.NotFound,
                            ...initializedPage,
                        } as NotFoundPage,
                    };
                }

                //Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
                const resultPage = {
                    component: componentName,
                    docs: documents,
                    size,
                    filter,
                    backTo,
                    documentType: documentType,
                    ...initializedPage,
                } as DocumentsPage;

                return { page: resultPage };
            }),
        );
    };

    return handler;
}

export function createOptions<TFilter extends Filter>(params?: Params, filter?: TFilter, onlyItems?: boolean): { page: { index: number; size: number } } & TFilter {
    const index = (params && params.index) || 0;
    const page = onlyItems
        ? { index, size }
        : { index: 0, size: size + size * index };

    return { ...filter, page } as { page: { index: number; size: number } } & TFilter;
}

export function normalizeFilter<TFilter extends Filter>(filter?: TFilter): TFilter {
    if (filter && filter.orderDate)
        return filter;

    const from = new Date();
    let fromDate = from.getDate();
    const fromMonth = from.getMonth() - 3;
    const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
    if (fromMonthLastDay < fromDate)
        fromDate = fromMonthLastDay;

    from.setMonth(fromMonth, fromDate);

    return { ...filter, orderDate: { from: DateOnly.toISOString(from) } } as TFilter;
}

function getNumberOfDaysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
}

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
type Params = {
    filter: Filter;
    index?: number;
    previewToken?: string;
    sortAscending: boolean;
    sortField: string;
};

type DocumentsRouteData = {
    routeName:
    | RouteName.Orders
    | RouteName.Quotes
    | RouteName.Invoices
    | RouteName.ReturnOrders
    | RouteName.CreditNotes
    | RouteName.ReturnReceipts
    | RouteName.Shipments;
    params?: Params;
    options?: {
        onlyItems?: boolean;
        backTo?: BackTo;
    };
};

type NotFoundPage = {
    component: PageComponentNames.NotFound;
};

type DocumentsPreviewPageResponse = {
    pages: {
        [documents in ReturnType<typeof getDocumentField>]: SystemPageData;
    };
};

type DocumentsPageResponse = {
    pages: {
        page: SystemPageData;
    };
} & DocumentsResponse;

type DocumentsPage = SystemPage & {
    component: PageComponentNames;
    docs: DocumentsCollection;
    size: number;
    filter: Filter;
    backTo?: BackTo;
};

//Ticket 220268: [UA] P3 - 3.1. Generic MTR Search
type FilterDoc = SystemPage & {
    component: PageComponentNames;
    docs: DocumentsCollection;
    size: number;
    filter: Filter;
    backTo?: BackTo;
    from: string,
    to: string,
};
